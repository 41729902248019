<template>
  <credentials-layout title="Reset Password">
    <div class="w-80 mx-auto my-24">
      <div
        class="
          mx-auto
          max-w-md
          px-6
          py-12
          bg-white
          border-0
          shadow-lg
          rounded-3xl
          my-auto
        "
      >
        <h1 class="text-lg font-bold mb-8 text-center">Account recovery</h1>
        <form id="form" novalidate>
          <div class="relative z-0 w-full mb-5">
            <input
              type="text"
              name="name"
              placeholder=" "
              required
              class="
                pt-3
                pb-2
                block
                w-full
                px-0
                mt-0
                bg-transparent
                border-0 border-b-2
                appearance-none
                focus:outline-none focus:ring-0 focus:border-black
                border-gray-200
              "
              :class="{
                'border-red-600': errors.email,
                'text-red-600': errors.email,
                'animate-wggle': errors.email,
              }"
              @change="validateEmail()"
              v-model="username"
            />
            <label
              for="name"
              class="
                absolute
                duration-200
                top-3
                -z-1
                origin-0
                text-gray-500 text-sm
                capitalize
              "
              >email</label
            >
            <span class="text-xs font-extralight text-red-600">{{
              errors.email
            }}</span>
          </div>

          <button
            id="button"
            type="button"
            class="
              w-full
              px-6
              py-3
              mt-3
              text-base whitespace-nowrap text-white
              transition-all
              duration-150
              ease-linear
              rounded-lg
              font-bold
              shadow
              outline-none
              bg-orange
              hover:shadow-xl
              focus:outline-none
              capitalize
            "
            @click="resetPassword()"
          >
            Send Password Reset Email
          </button>
        </form>
      </div>
    </div>
    <p class="text-center">Don’t have an account?</p>
    <p class="text-center">
      <n-button
        text
        type="primary"
        class="text-orange"
        @click="() => router.push('/join')"
      >
        Sign Up
      </n-button>
      instead
    </p>
  </credentials-layout>
</template>


<script>
import { NButton } from "naive-ui";
import { useRouter } from "vue-router";
import { mapActions, mapMutations } from "vuex";
import { isEmail, required } from "../helpers/validations";
import credentialsLayout from "../components/base/credentialsLayout.vue";

import {
  getAuth,
  sendSignInLinkToEmail,
} from "firebase/auth";

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // url: 'https://students.tutoruu.com/change-password',
  url: 'tutoruu.com/account/update-password?redirect_url=https://preview.students.tutoruu.com/profile',
  // This must be true.
  handleCodeInApp: true,
};


export default {
  name: "Login",
  components: { NButton, credentialsLayout },
  data() {
    return {
      username: "",
      password: "",
      errors: {
        email: null,
        password: null,
      },
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    ...mapActions(["login_user", "get_user"]),
    ...mapMutations(["setToken", "fireToast"]),
    async resetPassword() {
      this.validateEmail();
      if (!this.errors.email) {
        const auth = getAuth();
        sendSignInLinkToEmail(auth, this.username, actionCodeSettings )
          .then(() => {
            // password reset email sent
            window.localStorage.setItem('emailForSignIn', this.username);
            this.$router.push(`/reset-password/sent`);
          })
          .catch((error) => {
            const errorCode = error.code;
            this.fireToast({
              text: `error :( - ${errorCode}`,
              type: "error",
            });
          });
      }
    },
    validateEmail() {
      let missing = required(this.username);
      let notEmail = isEmail(this.username);
      this.errors.email = missing ? missing : notEmail ? notEmail : null;
    },
  },
};
</script>

<style scoped>
.-z-1 {
  z-index: -1;
}

.origin-0 {
  transform-origin: 0%;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value=""]):valid ~ label {
  /* @apply transform; scale-75; -translate-y-6; */
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1.9rem;
}

input:focus ~ label,
select:focus ~ label {
  /* @apply text-black; left-0; */
  --tw-text-opacity: 1;
  /* color: rgba(0, 0, 0, var(--tw-text-opacity)); */
  left: 0px;
  color: #ff8303;
}
input:focus {
  border-color: #ff8303;
}
</style>
